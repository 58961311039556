export default defineNuxtRouteMiddleware((to, _from) => {
  console.log("[Middleware] auth")

  const store = useStore()

  if (!store.isLoggedIn && to.path !== "/login") {
    console.log("no user found")
    return navigateTo("/login")
  }
})
